// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "L_hC";
export var customEnterpriseDevCallToActionSection = "L_hx";
export var customEnterpriseDevCaseSection = "L_hK";
export var customEnterpriseDevCostSection = "L_hD";
export var customEnterpriseDevExperienceSection = "L_hJ";
export var customEnterpriseDevExpertiseSection = "L_hH";
export var customEnterpriseDevIndustriesSection = "L_hw";
export var customEnterpriseDevPracticesSection = "L_hF";
export var customEnterpriseDevPrimeSection = "L_ht";
export var customEnterpriseDevProcessSection = "L_hz";
export var customEnterpriseDevProjLogoSection = "L_hG";
export var customEnterpriseDevQuotesSection = "L_hL";
export var customEnterpriseDevServicesSection = "L_hv";
export var customEnterpriseDevSolutionSection = "L_hy";
export var customEnterpriseDevTechSection = "L_hB";